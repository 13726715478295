import React, { Component, createRef } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../routes.js";
import Footer from "../components/Footers/DarkFooter";
import Sidebar from "../components/SideBar/Sidebar";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
    };
    this.mainPanel = createRef();
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  componentDidUpdate(e) {
    if (
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.current.scrollTop = 0;
    }
  }

  render() {
    return (
      <div className="">
        <Sidebar
          {...this.props}
          routes={routes}
          image={this.state.image}
          color={this.state.color}
        />
        <div id="main-panel" className="main-panel" ref={this.mainPanel}>
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        <Footer className="footer" />
      </div>
    );
  }
}

export default Admin;
