import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Index from "./views/Index.js";
import LoginPage from "./views/Pages/LoginPage.js";
import ForgotPasswordPage from "./views/Pages/ForgotPasswordPage.js";
import ResetPassword from "./views/Pages/ResetPassword.js";
import AdminLayout from "./views/Admin.jsx";
import SignUpPage from "./views/Pages/SignUpPage.js";
import PageDetail from "./views/Pages/PageDetail.jsx";
import EditAsHTML from "./views/Pages/EditAsHTML.jsx";
import Logout from "./views/Pages/Logout.js";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

const App = () => (
  <Switch>
    <Route path="/index" component={Index} />
    <Route path="/login-page" component={LoginPage} />
    <Route path="/forgot-password" component={ForgotPasswordPage} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/admin" component={AdminLayout} />
    <Route path="/signUp-page" component={SignUpPage} />
    <Route path="/admin/pageDetails" component={PageDetail} />
    <Route path="/admin/editAsHTML" component={EditAsHTML} />
    <Route path="/logout" component={Logout} />
    <Redirect from="/" to="/index" />
  </Switch>
);

export default App;
