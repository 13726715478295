import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { DataBaseMiddleware } from "../../store/middlewares";
import { StatsCard } from "../../components/StatsCard/StatsCardPage";
import { Colors } from "../../config";
import { withRouter } from "react-router-dom"; // Import withRouter
import noPage from "../../assets/img/NoPage.png";
import axios from "axios";

class Pages extends Component {
  componentWillMount() {
    const { GetPages } = this.props;
    GetPages(localStorage.getItem("userId"));
  }

  componentWillUnmount() {
    console.log("unmount working===>");
  }

  UNSAFE_componentWillUpdate() {
    console.log("Did update works===>");
  }

  deletePage = (pageId) => {
    const { GetPages } = this.props;
    axios
      .post(`https://magicpages.propstory.com/page/remove`, {
        page_id: pageId,
      })
      .then(() => GetPages(localStorage.getItem("userId")))
      .catch((error) => {
        if (error.response) {
          // Server responded with a status other than 200 range
          console.log("Error response:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else if (error.request) {
          // Request was made but no response was received
          console.log("Error request:", error.request);
        } else {
          // Something else happened in setting up the request
          console.log("Error message:", error.message);
        }
        console.log("Error config:", error.config);
      });
  };

  render() {
    const { pages, history } = this.props;

    return (
      <div className="content" style={{ position: "relative" }}>
        <Container fluid className="text-center">
          <Row>
            {pages.length <= 0 || pages.length === undefined ? (
              <img
                alt="no page"
                src={noPage}
                width="60%"
                style={{
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              />
            ) : (
              pages &&
              pages.map((item, index) => {
                return (
                  <Col lg={4} sm={8} key={item._id}>
                    <StatsCard
                      key={index}
                      MainImage={<img alt="" src={item.image} />}
                      statsColor={Colors.LightBlue}
                      statsHeading={item.name}
                      frame={item.html}
                      statsBtn2Text="Delete Page"
                      statsBtn1Text="Edit Page"
                      onClickEdit={() => {
                        history.push({
                          pathname: "/admin/pageDetails",
                          search: `?page_id=${item._id}`,
                          state: { item },
                        });
                      }}
                      onClickDelete={() => this.deletePage(item._id)}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(states) {
  return {
    userId: states.AuthReducer.user.id,
    pages: states.DataBaseReducer.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetPages: (userId) => {
      dispatch(DataBaseMiddleware.GetPages(userId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pages));
