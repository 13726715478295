import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("user_data");
    history.push("/login-page");
  }, [history]);

  return null;
};

export default Logout;
