import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { FiHash } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Navbar } from "react-bootstrap";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  list: {
    width: drawerWidth,
  },
  fullList: {
    width: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const Sidebar = ({ routes }) => {
  const classes = useStyles();
  const location = useLocation();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    setState({ ...state, left: open });
  };

  const handleDrawerClose = () => {
    setState({ ...state, left: false });
  };

  function activeRoute(routeName) {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Divider />
      <List>
        {routes.map((prop, key) => {
          if (!prop.redirect)
            return (
              <li
                className={
                  prop.upgrade
                    ? "active active-pro"
                    : activeRoute(prop.layout + prop.path)
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link d-flex"
                  activeClassName="active"
                  style={{ alignItems: "center" }}
                >
                  <ListItem button key={prop.name}>
                    <ListItemIcon>
                      {<FiHash size={24} color="#6927ff" />}
                    </ListItemIcon>
                    <ListItemText
                      style={{ fontWeight: "bold" }}
                      primary={prop.name}
                    />
                  </ListItem>
                </NavLink>
              </li>
            );
          return null;
        })}
      </List>
    </div>
  );

  return (
    <>
      <Navbar bg="light" expand="xl">
        <Navbar.Brand>
          <Button
            className="Button"
            style={{ outline: "none" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon color="secondary" fontSize="large" />
          </Button>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 20,
              fontFamily: "Montserrat",
              verticalAlign: "middle",
            }}
          >
            PROPSTORY MAGIC PAGES
          </p>
        </Navbar.Brand>
      </Navbar>
      <Drawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer(false)}
        className={clsx(classes.drawer)}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon color="primary" />
          </IconButton>
        </div>
        {list()}
      </Drawer>
    </>
  );
};

export default Sidebar;
