import React, { useState, useEffect } from "react";
import axios from "axios";
import LeadBackupTable from "../../components/LeadBackupTable/LeadBackupable";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const loadingOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999, // Ensure it overlays other content
};

const BackupLeads = () => {
  const [backupData, setBackupData] = useState([]);
  const [urls, setUrls] = useState([]);
  const [chosenUrlData, setChosenUrlData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    console.log("ON backup leads page");
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://propstory.com/optima/campaign/getLPBackupLeads",
          getRequestData(),
          {
            headers: {
              "Referrer-Policy": "no-referrer",
            },
          }
        );

        const data = response.data.data;
        const filteredUrls = filterUrls(data);
        setBackupData(data);
        setUrls(filteredUrls);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getRequestData = () => {
    const today = new Date();
    const monthBefore = new Date(today.setMonth(today.getMonth() - 1));
    const startDate = formatDate(monthBefore);
    const endDate = formatDate(new Date());
    return {
      start_time: startDate,
      end_time: endDate,
    };
  };

  const formatDate = (date) => {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const filterUrls = (data) => {
    const uniqueUrls = [
      ...new Set(
        data.map((item) => item.page_url.split("?")[0]).filter((url) => url)
      ),
    ];
    return uniqueUrls;
  };

  const handleChangeUrl = (input) => {
    const chosenUrlData = backupData.filter(({ page_url }) =>
      page_url.includes(input)
    );
    setChosenUrlData(chosenUrlData);
    setSearch(input);
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center loading-overlay"
          style={{
            height: "100vh",
            background: "rgba(255, 255, 255, 1)",
          }}
        >
          <div style={loadingOverlayStyle}>
            <CircularProgress size={100} style={{ color: "#F50157" }} />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5">
            <Autocomplete
              id="Search-box"
              options={urls}
              style={{ width: 600 }}
              onInputChange={(a, input) => handleChangeUrl(input)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Landing Page Url"
                  variant="outlined"
                  value={search}
                />
              )}
            />
          </div>
          <div className="flex-grow-1">
            <LeadBackupTable url={search} data={chosenUrlData} />
          </div>
        </>
      )}
    </div>
  );
};

export default BackupLeads;
